<template>
  <v-dialog
    v-bind="$attrs"
    v-model="show"
    :max-width="maxWidth"
    content-class="dialogConfirm"
    transition="scroll-y-transition"
    overlay-color="overlayColor"
  >
    <v-tabs-items v-model="currentTab" touchless>
      <v-tab-item :value="tabs.default">
        <v-card class="cardBg px-10 py-25 px-sm-32 py-sm-32">
          <div class="d-flex flex-wrap align-center pb-4">
            <div class="font-large-title text-uppercase dialogConfirmTitle--text pr-11 pb-2">{{ title }}</div>

            <div class="font-large-title dialogConfirmSubTitle--text pb-2 text-truncate">{{ subTitle }}</div>
          </div>
          <div class="pb-10 pb-sm-18">
            <h3 class="font-normal dialogConfirmQuestion--text pb-4">{{ message }}</h3>

            <h3 class="py-4 font-normal dialogConfirmQuestion--text" v-if="attachedItems">
              {{ attachedItems['currentName'] }} is currently associated with the following items:
            </h3>

            <v-card class="cardBgChild" v-if="attachedItems">
              <v-card-title>
                <v-row no-gutters>
                  <v-col cols="12" md="4">{{ attachedItems['attachedItemName'] }}:</v-col>
                  <v-col cols="12" md="8">
                    <div
                      class="d-flex justify-space-between"
                      v-for="item in attachedItems.items"
                    >
                      <span>{{ item.name }}</span>

                      <v-btn
                        icon
                        tag="router-link"
                        small
                        :to="{ name: 'Campaigns', query: { slug: item.slug }}"
                        target="_blank"
                      >
                        <v-tooltip color="tooltipBg" open-delay="150" bottom>
                          <template #activator="{ on }">
                            <v-icon v-on="on">open_in_new</v-icon>
                          </template>
                          <span class="font-small">View campaign</span>
                        </v-tooltip>

                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>

            <PrimaryCheckbox
              v-if="attachedItems"
              v-model="successDelete"
              :label="messageSuccess"
              hide-details
            />

            <PrimaryCheckbox
              v-if="detachFromVendor"
              v-model="detachSuccessFormVendor"
              label="Detach the number from the Vendor"
              hide-details
            />

            <PrimaryCheckbox
              v-if="unrentNumbers"
              v-model="unrentSuccessAllNumbers"
              label="Unrent numbers"
              hide-details
            />
          </div>

          <div class="d-flex justify-end">
            <ActionButton
              class="mr-5 mr-sm-11"
              :loading="loading"
              :disabled="attachedItems && !successDelete"
              @click.prevent="makeAction"
            >
              {{ confirmButtons[0] }}
            </ActionButton>

            <CancelButton @click="closeDialog">{{ confirmButtons[1] }}</CancelButton>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="tabs.confirm">
        <DialogCardConfirm
          class="pa-15 pa-sm-20"
          :show="show"
          :loading="loading"
          @sendConfirmData="sendConfirmData"
          @closeDialog="closeDialog"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import DialogCardConfirm from '@/components/cards/DialogCardConfirm'

export default {
  name: 'DialogConfirm',
  inheritAttrs: false,
  components: { PrimaryCheckbox, ActionButton, CancelButton, DialogCardConfirm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    attachedItems: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Delete'
    },
    subTitle: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: 'Are you sure you want to delete this item?'
    },
    messageSuccess: {
      type: String,
      default: 'I understand that deleting this item will activate the last used route on the Campaigns or Routing Plans listed above.'
    },
    detachFromVendor: {
      type: Boolean,
      default: null
    },
    unrentNumbers: {
      type: Boolean,
      default: null
    },
    maxWidth: {
      type: String | Number,
      default: 450
    },
    confirmButtons: {
      type: Array,
      default: () => ['Yes', 'No']
    },
    withAuthConform: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    load: false,
    successDelete: false,
    detachSuccessFormVendor: false,
    unrentSuccessAllNumbers: false,
    currentTab: 'default',
    tabs: {
      default: 'default',
      confirm: 'confirm',
    }
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    makeAction() {
      if (this.withAuthConform) {
        this.currentTab = this.tabs.confirm
      } else {
        this.$emit('actionSuccess', {
          detachSuccessFormVendor: this.detachSuccessFormVendor ? true : null,
          unrent: this.unrentSuccessAllNumbers ? true : null,
        })
      }
    },
    sendConfirmData(formData) {
      this.$emit('actionSuccess', formData)
    },
    closeDialog() {
      this.show = false
      this.$emit('cancel')
    },
  },
  watch: {
    show: {
      handler(value) {
        if (!value) {
          this.detachSuccessFormVendor = false
          this.unrentSuccessAllNumbers = false
          this.currentTab = this.tabs.default
        }
      }
    }
  }
}
</script>
