<template>
  <v-card class="cardBg" outlined>
    <v-form ref="form" v-model="validateForm" v-if="show">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column align-start justify-center pr-0 pr-sm-11 pb-16"
          cols="12"
        >
          <span class="font-large-title text-uppercase grayLighten3--text">Confirm your authentication</span>
        </v-col>

        <v-col
          class="d-flex flex-column align-start align-sm-end justify-center pr-11 pb-4 pb-sm-10"
          cols="12" sm="4"
        >
          <span class="font-normal nameField--text">Email</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="apiTokenEmail"
          ></portal-target>
        </v-col>
        <v-col cols="12" sm="8" class="mt-7 mt-sm-0 pb-8 pb-sm-10">
          <FormField
            v-model="formData.email"
            :rules="emailRules"
          >
            <template #message="{ key, message }">
              <portal to="apiTokenEmail" v-if="!!message">
                {{ message }}
              </portal>
            </template>
          </FormField>
        </v-col>

        <v-col
          class="d-flex flex-column align-start align-sm-end justify-center pr-11"
          cols="12" sm="4"
        >
          <span class="font-normal nameField--text">Password</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="apiTokenPassword"
          ></portal-target>
        </v-col>
        <v-col cols="12" sm="8" class="mt-7 mt-sm-0">
          <FormField
            v-model="formData.password"
            :rules="passwordRules"
            type="password"
          >
            <template #message="{ key, message }">
              <portal to="apiTokenPassword" v-if="!!message">
                {{ message }}
              </portal>
            </template>
          </FormField>
        </v-col>
      </v-row>

      <v-card-actions class="justify-end pr-0 pt-0 mt-16">
        <ActionButton class="mr-5 mr-sm-10" :loading="loading" @click="submitForm">Confirm</ActionButton>

        <CancelButton @click="closeDialog">Cancel</CancelButton>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

export default {
  name: 'DialogCardConfirm',
  inheritAttrs: false,
  components: { FormField, ActionButton, CancelButton },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    formData: {
      email: null,
      password: null,
    },
    validateForm: true,
    emailRules: [],
    passwordRules: [],
  }),
  methods: {
    async submitForm() {
      await this.validationForm()

      if (this.$refs.form.validate()) {
        this.$emit('sendConfirmData', this.formData)
      }
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
      this.passwordRules = [
        v => !!v ||  'Password is required'
      ]
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    clearForm() {
      setTimeout(() => {
        this.emailRules = []
        this.passwordRules = []
        this.formData.email = null
        this.formData.password = null
      })
    },
  },
  watch: {
    show: {
      handler(show) {
        if (!show) {
          this.clearForm()
        }
      }
    },
  },
}
</script>